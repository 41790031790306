export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const LIKE = 'LIKE';
export const UNLIKE_POST = 'UNLIKE_POST';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';

export const CREATE_COMMENT = 'CREATE_COMMENT';
export const FETCH_ALL_COMMENTS = 'FETCH_ALL_COMMENTS';

export const CREATE_ERROR = 'CREATE_ERROR';
export const FETCH_USER_POSTS = 'FETCH_USER_POSTS'

