import { useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../actions/users";
import './SignUpButton.css';

const SignUpButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState('');
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'userName') {
            setUserName(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'email') {
            setEmail(value);
        }
    };

    const showSignupDialog = () => {
        setShowDialog(true);
    };

    const hideSignupDialog = () => {
        setShowDialog(false);
        setUserName('');
        setEmail('');
        setPassword('');
        setErrorText('');
    };

    const clear = () => {
        setUserName('');
        setPassword('');
        setEmail('');
        setErrorText('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(signup(userName, email, password))
            .then(() => {
                console.log("Pushed submit");
                setShowDialog(false);
                clear();
            })
            .catch((error) => {
                console.log('Fehler bei der Registrierung:', error.message);
                setErrorText(<span style={{ color: 'red' }}>{error.message}</span>);
            });
    };

    return (
        <div>
            {!user && (
                <div id="button">
                    <a className="signUp" onClick={showSignupDialog}>
                        Registrieren
                    </a>
                </div>
            )}

            <Modal show={showDialog} onHide={hideSignupDialog} >
                <Modal.Header closeButton>
                    <Modal.Title>SignUp</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} >
                        {errorText && <div className="error-text">{errorText}</div>}
                        <div className="form-group">
                            <label htmlFor="userName">Username</label>
                            <input
                                type="text"
                                className="form-control"
                                id="userName"
                                name="userName"
                                placeholder="Username eingeben"
                                value={userName}
                                onChange={handleInputChange}
                                style={{ marginBottom: '20px' }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="userName">E-Mail</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="E-Mail Adresse eingeben"
                                value={email}
                                onChange={handleInputChange}
                                style={{ marginBottom: '20px' }}
                            />
                        </div>
                        <div className="form-group">
                        <label htmlFor="password">Passwort</label>
                            <p className="password-requirements">
                            Das Passwort muss mindestens 8 Zeichen lang sein. Es muss mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.
                            </p>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder="Passwort eingeben"
                                value={password}
                                onChange={handleInputChange}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <div className="signUpSubmit">
                    <Button variant="primary" className="custom-button" type="submit" onClick={handleSubmit}>
                        Registrieren
                    </Button>
                </div>
            </Modal>
        </div>
    );
}


export default SignUpButton;