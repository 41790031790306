import { useEffect, useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import { useDispatch, useSelector } from "react-redux";
import { getPlacesByUser, getPosts } from "../actions/posts";
import './PlaceMarker.css';

const PlaceOverview = ({}) => {
  const posts = useSelector((state) => state.posts);
  const user = useSelector((state) => state.auth.user);
  const [filterActive, setFilterActive] = useState(false); // Zustandsvariable für den Filter
  const [buttonText, setButtonText] = useState(""); // Zustandsvariable für den Text des Buttons
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPlaces();
  }, []);

  const fetchPlaces = async () => {
    try {
      if (user) {
        const userId = user._id;
        const defaultLatitude = 52.546420;
        const defaultLongitude = 13.353118;
        if (filterActive) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
        
              // Übergeben der Koordinaten an die Backend-Route
              dispatch(getPosts(latitude, longitude));
            },
            (error) => {
              dispatch(getPosts(defaultLatitude, defaultLongitude));
            }
          );
            setFilterActive(false);
            console.log(posts);
        } else {
          // Filter ist nicht aktiv, also aktivieren
          setFilterActive(true);
          dispatch(getPlacesByUser(userId));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 900) {
        setButtonText(filterActive ? "All" : "My"  );
      } else {
        setButtonText(filterActive ? "Alle Places" : "Meine Places" );
      }
    };
    handleWindowResize(); // initialer Text basierend auf der aktuellen Bildschirmgröße
    window.addEventListener("resize", handleWindowResize); // Event-Listener für Fenstergrößenänderungen

    return () => {
      window.removeEventListener("resize", handleWindowResize); // Event-Listener entfernen, um Memory-Leaks zu vermeiden
    };
  }, [filterActive]);

  return (
    <div>
      {user && (
        <div id="placesSwitchBtn">
          <Button variant="primary" className="custom-button" onClick={fetchPlaces}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PlaceOverview;
