import { useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/users";
import './LogInButton.css';
import SignUpButton from './SignUpButton';

const LogInButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'userName') {
      setUserName(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };


  const showLoginDialog = () => {
    setShowDialog(true);
  };

  const hideLoginDialog = () => {
    setShowDialog(false);
    setUserName('');
    setPassword('');
    setErrorText('');
  };

  const clear = () => {
    setUserName('');
    setPassword('');
    setErrorText('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(userName, password))
      .then(() => {
        setShowDialog(false);
        clear();
      })
      .catch((error) => {
        console.log('Fehler bei der Anmeldung:', error.message);
        setErrorText(<span style={{ color: 'red' }}>{error.message}</span>);
      });
  };

  return (
    <div>
      {!user && (
        <div id="button">
          <Button id="loginbtn" variant="primary" className="custom-button" onClick={showLoginDialog}>
            Login
          </Button>

        </div>
      )}

      <Modal show={showDialog} onHide={hideLoginDialog} >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} >
            {errorText && <div className="error-text">{errorText}</div>}
            <div className="form-group">
              <label htmlFor="userName">Username</label>
              <input
                type="text"
                className="form-control"
                id="userName"
                name="userName"
                placeholder="Username eingeben"
                value={userName}
                onChange={handleInputChange}
                style={{ marginBottom: '20px' }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Passwort</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="Passwort eingeben"
                value={password}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </Modal.Body>

        <div className="LoginFooter">
          <div className="leftSection">
            <p>Noch kein Konto?</p>
            <SignUpButton />
          </div>
          <div className="rightSection">
            <Button variant="primary" className="custom-button" type="submit" onClick={handleSubmit}>
              Login
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default LogInButton;