import { CREATE_COMMENT, UPDATE, DELETE, LIKE, FETCH_ALL_COMMENTS } from "../constants/actionTypes";

export default (comments = [], action) => {
    switch (action.type) {

        case DELETE:
            return comments.filter((comment) => comment._id !== action.payload);
        case UPDATE:
        case LIKE:
            return comments.map((comment) => comment._id == action.payload._id ? action.payload : comment);
        case FETCH_ALL_COMMENTS:
            return action.payload;
        case CREATE_COMMENT:
            return [...comments, action.payload];
        default:
            return comments;
    }
}