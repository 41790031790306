import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { Circle, MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from "react-redux";
import { deletePost } from "../actions/posts";
import { getPosts } from "../actions/posts.js";
import FormButton from './Form/Form';
import LogInButton from './LogInButton';
import LogoutButton from "./LogoutButton";
import { DraggableMarker } from './Marker';
import PlaceMarker from "./PlaceMarker";
import PlaceOverview from './PlaceOverview';
import './StartPage.css';
import TagsInput from "./TagsInput";
import config from './config';

function LocateControl({ setMarkerPosition }) {
  useMapEvents({
    click: (e) => {
      const { lat, lng } = e.latlng;
      setMarkerPosition([lat, lng]);
    },
  });

  return (
    <div className="locate-control">
      {/* Render the locate control */}
    </div>
  );
}

function StartPage() {
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  const [markerVisible, setMarkerVisible] = useState(false);
  const [position, setPosition] = useState(null);
  const [markerPosition, setMarkerPosition] = useState([52.546420, 13.353118]);
  const comments = useSelector((state) => state.comments);
  const [filteredPosts, setFilteredPosts] = useState(null); //Suchanfragen
  const mapRef = useRef(null); // create a reference to the Map object
  const [showModal, setShowModal] = useState(false);
  const [radius, setRadius] = useState(5000); // Initialer Radiuswert
  const [showFeedbackStartpage, setShowFeedbackStartpage] = useState(false);
  const [feedbackMessageStartpage, setFeedbackMessageStartpage] = useState('');
  const posts = useSelector((state) => state.posts);

  const [postData, setPostData] = useState({
    title: "",
    location: {
      coordinates: [0, 0]
    },
    creator: "",
    tags: "",
    selectedFile: "",
    description: ""
  });

  const toggleMarkerVisibility = (value) => {
    setMarkerVisible(value);
    if (value) {
      showFeedbackWindowStartpage("Bitte den Marker auf den gewünschten Ort verschieben und loslassen.", true, 5000);
    }
  };

  const showFeedbackWindowStartpage = (message, show = true, duration = 3000) => {
    setFeedbackMessageStartpage(message);
    setShowFeedbackStartpage(show);
    setTimeout(() => {
      setShowFeedbackStartpage(!show);
    }, duration);
  };

  const handleSearch = useCallback((searchTags) => {
    if (searchTags.length === 0) {
      setFilteredPosts(null);
    } else {
      const filteredPosts = posts.filter((post) => {
        return searchTags.some((tag) => post.tags.includes(tag));
      });
      setFilteredPosts(filteredPosts);
    }
  }, [posts]);

  const renderMarkers = () => {
    const postsToRender = Array.isArray(filteredPosts) ? filteredPosts : Array.isArray(posts) ? posts : [];
    return postsToRender.map((post) => (
      <PlaceMarker key={post._id} post={post} setCurrentId={setCurrentId} deletePost={deletePost} comments={comments}>
        {comments
          .filter((comment) => comment.place === post._id)
          .map((comment) => (
            <div key={comment._id} className="comment-box">
              <p className="comment-text">
                <span className="comment-user">{comment.author}</span>: {comment.text}
              </p>
            </div>
          ))}
      </PlaceMarker>
    ));
  };

const handleRadiusChange = useMemo(() => async (radius) => {
  const defaultLatitude = 52.546420;
  const defaultLongitude = 13.353118;

  setRadius(radius);
  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    dispatch(getPosts(latitude, longitude/*, radius*/));
    } catch (error) {
      console.log('Fehler beim Aktualisieren des Radius:', error);
      dispatch(getPosts(defaultLatitude, defaultLongitude, radius));
    }
  }, [dispatch]); // Keep 'dispatch' in the dependency array

  useEffect(() => {
    const getCurrentLocation = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPosition([latitude, longitude]);

        },
        (error) => {
          console.log("Fehler beim Abfragen des Standorts:", error);
          setPosition([52.546420, 13.353118]);
        }
      );
    };

    getCurrentLocation();
  }, []);

  //console.log(position);

  if (position === null) {
    return <div>Standortzugriff kontrollieren...</div>
  }

  //console.log(radius)
  return (
    <div className="page-content" >
      <div className="menu">

        <div className="logoHeader">
          <h1 className="headline">PLACES</h1>
          <img src="Logo.png" alt="Icon der Webseite Places" />
        </div>

        <TagsInput onSearch={handleSearch} />

        <div className="myPlacesAndLogout">
          <Dropdown style={{ display: 'none' }}>
            <Dropdown.Toggle className="radius" variant="secondary" id="dropdown-radius">
              <img src="radius.png" alt="Radius Icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleRadiusChange(5000)}>5 km</Dropdown.Item>
              <Dropdown.Item onClick={() => handleRadiusChange(10000)}>10 km</Dropdown.Item>
              <Dropdown.Item onClick={() => handleRadiusChange(20000)}>20 km</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <LogInButton />

          <PlaceOverview></PlaceOverview>

          <LogoutButton />
        </div>
        <div id="addPlace">
          <FormButton className="custom-button"
            currentId={currentId}
            setCurrentId={setCurrentId}
            onToggleMarkerVisibility={toggleMarkerVisibility}
            showModal={showModal}
            setShowModal={setShowModal}
            postData={postData}
            setPostData={setPostData} 
            showFeedbackWindowStartpage={showFeedbackWindowStartpage}
          />
        </div>
      </div>

      <MapContainer
        center={position}
        zoom={15}
        scrollWheelZoom={true}
        style={{ height: '100vh', width: '100%' }}
        whenCreated={(map) => { mapRef.current = map; }} // set the reference to the Map object
        minZoom={3} // Set the minimum zoom level
        maxBounds={[
          [-80, -200], // Untere linke Ecke des begrenzenden Bereichs
          [80, 200]  // Obere rechte Ecke des begrenzenden Bereichs
        ]}
        zoomControl={false}
      >
        {!config.mapbox.id || !config.mapbox.accessToken ? (
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        ) : (
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${config.mapbox.accessToken}`}
            attribution="&copy; OpenStreetMap contributors"
            id={config.mapbox.id}
            tileSize={512}
            zoomOffset={-1}
          />
        )}

        <Circle
          center={position}
          radius={radius}
          key={radius}
          pathOptions={{ fillOpacity: 0, fillColor: 'rgb(262, 130, 100)', color: 'rgb(212, 73, 19)', weight: 0 }}
        ></Circle>

        {showFeedbackStartpage && (
          <div className="feedback-window">
            <div className="feedback-message">Info:</div>
            <div className="feedback-info">{feedbackMessageStartpage}</div>
          </div>
        )}
        <DraggableMarker
          currentId={currentId}
          setCurrentId={setCurrentId}
          setPosition={setPosition}
          visible={markerVisible}
          onToggleMarkerVisibility={toggleMarkerVisibility}
          setShowModal={setShowModal}
          setPostData={setPostData}
          markerPosition={markerPosition}
          setMarkerPosition={setMarkerPosition}
        />

        {renderMarkers()}
        <LocateControl
          position={position}
          mapRef={mapRef}
          setMarkerPosition={setMarkerPosition}
        />
      </MapContainer>
    </div>
  );
}

export default StartPage;
