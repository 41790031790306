import L from 'leaflet';
import { useRef } from "react";
import { Marker, Popup } from 'react-leaflet';

const myIcon = L.icon({
  iconUrl: 'placeMarker.png',
  iconSize: [32, 32],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38]
});

let globalLat = 0; // Globale Variable für lat
let globalLng = 0; // Globale Variable für lng

function DraggableMarker({ visible, onToggleMarkerVisibility, setShowModal, setPostData, markerPosition, setMarkerPosition }) {
  const markerRef = useRef(null);

  const toggleMarkerVisibility = (visible) => {
    onToggleMarkerVisibility(visible);
  };

  const handleDragEnd = () => {
    const marker = markerRef.current;
    if (marker != null) {
      const latlng = marker.getLatLng();
      const { lat, lng } = latlng;
      setMarkerPosition([lat, lng]);
      globalLat = lat; // Speichern der lat in der globalen Variable
      globalLng = lng; // Speichern der lng in der globalen Variable
      toggleMarkerVisibility(false);
      setShowModal(true);
      setPostData((prevData) => ({
        ...prevData,
        location: {
          type: "Point",
          coordinates: [lng, lat]
        }
      }));
    }
  };

  return (
    <>
      {visible && ( // Prüfen, ob der Marker sichtbar sein soll
        <Marker
          draggable
          eventHandlers={{
            dragend: handleDragEnd,
          }}
          position={markerPosition}
          ref={markerRef}
          icon={myIcon}
          opacity={1} // Die Opazität auf 1 setzen, um den Marker sichtbar zu machen
        >
          <Popup>
            <span>Ziehe die Markierung auf den gewünschten Ort</span>
          </Popup>
        </Marker>
      )}
    </>
  );
}

export { DraggableMarker, globalLat, globalLng };

