import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from './App';
import "./components/App.css";
import reducers from './reducers';

const store = createStore(reducers, applyMiddleware(thunk))

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <App />
    </Provider>
);

export default store;