import * as api from "../api";
import { CREATE_COMMENT, DELETE, FETCH_ALL_COMMENTS, UPDATE } from "../constants/actionTypes";

export const getComment = (id) => async (dispatch) => {
    try {
        const { data } = await api.getComment(id);
        dispatch({ type: FETCH_ALL_COMMENTS, payload: data });
    } catch (error) {
        console.log(error)
    }
}

export const getCommentsFromPlace = (id) => async (dispatch) => {
    try {
        const { data } = await api.getCommentsFromPlace(id);

        dispatch({ type: FETCH_ALL_COMMENTS, payload: data });
    } catch (error) {
        console.log(error);
    }
};

export const createComment = (comment) => async (dispatch) => {
    console.log("in create comment action", comment)
    try {
        const { data } = await api.createComment(comment);
        console.log("comment action data: ", data)
        dispatch({ type: CREATE_COMMENT, payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const updateComment = (id, updatedComment) => async (dispatch) => {
    try {
        const { data } = await api.updateComment(id, updatedComment);

        dispatch({ type: UPDATE, payload: data })
    } catch (error) {
        console.log(error);
    }
}

export const deleteComment = (id) => async (dispatch) => {
    try {
        await api.deleteComment(id);

        dispatch({ type: DELETE, payload: id });
    } catch (error) {
        console.log(error);
    }
}

