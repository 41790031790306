import { LOGIN, LOGOUT, SIGNUP } from "../constants/actionTypes";
import * as api from "../api";

export const login = (userName, password) => async (dispatch) => {

  try {
    const { data } = await api.loginUser(userName, password);
    localStorage.setItem("token", data.token)
    dispatch({ type: LOGIN, payload: data.user });

  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

export const logout = () => {
  localStorage.removeItem('token');
  return {
    type: LOGOUT,
  };
};

export const signup = (userName, email, password) => async (dispatch) => {

  try {
    const { data } = await api.signupUser(userName, email, password);
    localStorage.setItem("token", data.token)
    dispatch({ type: SIGNUP, payload: data.user });

  } catch (error) {
    throw new Error(error.response.data.error);
  }
}

export const authenticateUser = (token) => async (dispatch) => {
  try {
    const response = await api.verifyUser(token);
    console.log(response);
    const user = response.data;

    const action = { type: 'AUTHENTICATE', payload: user };
    console.log(action);

    dispatch(action);
    } catch (error) {
    console.error('Es gab einen Fehler beim Authentifizieren des Benutzers:', error);
  }
};