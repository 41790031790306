import { LOGIN, LOGOUT, SIGNUP } from "../constants/actionTypes";

const initialState = {
  user: null,
  userName: "",
  email: ""
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload,
        userName: action.payload.userName,
        email: action.payload.email
      };

    case SIGNUP:
      return {
        ...state,
        user: action.payload,
        userName: action.payload.userName,
        email: action.payload.email
      };

    case LOGOUT:
      return { user: null };
    default:
      return state;

    case 'AUTHENTICATE':
      return {
        ...state,
        user: action.payload,
        userName: action.payload.userName,
        email: action.payload.email
      };
  }
};

export default authReducer;
