import * as api from "../api";
import { CREATE, CREATE_ERROR, DELETE, FETCH_ALL, FETCH_USER_POSTS, LIKE, UNLIKE_POST, UPDATE } from "../constants/actionTypes";

export const getPosts = (latitude, longitude/*, radius*/) => async (dispatch) => {
    try {
        const { data } = await api.fetchPosts(latitude, longitude/*, radius*/);
        dispatch({ type: FETCH_ALL, payload: data });
    } catch (error) {
        console.log(error);
    }
};

export const getPlacesByUser = (userId) => async (dispatch) => {
    try {
        const { data } = await api.fetchUserPosts(userId);
        dispatch({ type: FETCH_USER_POSTS, payload: data });
    } catch (error) {
        console.log(error)
    }
}

export const createPost = (post) => async (dispatch) => {
    try {
        const { data } = await api.createPost(post);

        dispatch({ type: CREATE, payload: data });
    } catch (error) {
        console.log(error.response);
        dispatch({ type: CREATE_ERROR, payload: error.message });
    }
};

export const updatedPost = (id, post) => async (dispatch) => {
    try {
        const { data } = await api.updatePost(id, post);

        dispatch({ type: UPDATE, payload: data })
    } catch (error) {
        console.log(error);
    }
}

export const deletePost = (id) => async (dispatch) => {
    try {
        await api.deletePost(id);

        dispatch({ type: DELETE, payload: id });
    } catch (error) {
        console.log(error);
    }
}

export const likePost = (id, userId) => async (dispatch) => {
    try {
        const { data } = await api.likePost(id, userId);

        dispatch({ type: LIKE, payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const unlikePost = (id, userId) => async (dispatch) => {
    try {
        const { data } = await api.unlikePost(id, userId);

        dispatch({ type: UNLIKE_POST, payload: data });
    } catch (error) {
        console.log(error);
    }
}


