import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/users";
import Button from 'react-bootstrap/esm/Button';

const LogoutButton = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());
    console.log("Pushed logout");
  };

  if (!user) {
    return null; // Verstecke den Logout-Button, wenn der Benutzer nicht angemeldet ist
  }

  return (
    <Button variant="secondary" className="logout-button" type="submit" onClick={handleLogout}>
      <img src="logout.png" width="24px" alt="Button zum ausloggen" />
    </Button>
  );
};

export default LogoutButton;
