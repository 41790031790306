// import axios from "axios";
//Senden von HTTP Anfragen und empfangen von Antworten vom Server
//um Daten abzurufen, erstellen, verändern, etc
import axios from "./config";

const url = "/posts"; // ganze Url in config
// export const fetchPosts = (latitude, longitude, radius) => axios.get(`${url}?latitude=${latitude}&longitude=${longitude}&radius=${radius}`);
export const fetchPosts = (latitude, longitude) => axios.get(`${url}?latitude=${latitude}&longitude=${longitude}`);
export const fetchUserPosts = (userId) => axios.get(`${url}/${userId}`);
export const createPost = (newPost) => axios.post(url, newPost);
export const updatePost = (id, updatedPost) => axios.patch(`${url}/${id}`, updatedPost);
export const deletePost = (id) => axios.delete(`${url}/${id}`);
export const likePost = (id, userId) => axios.patch(`${url}/${id}/likePost`, { userId });
export const unlikePost = (id, userId) => axios.patch(`${url}/${id}/unlikePost`, { userId });

const commentURL = "/comment"
export const getComment = (id) => axios.get(`${commentURL}/${id}`);
export const getCommentsFromPlace = (id) => axios.get(`${commentURL}/${id}/comments`);
export const createComment = (newComment) => axios.post(commentURL, newComment);
export const updateComment = (id, updatedComment) => axios.patch(`${commentURL}/${id}`, updatedComment);
export const deleteComment = (id) => axios.delete(`${commentURL}/${id}`);

const baseUrl = '/user'; // ganze Url in config
export const loginUser = (userName, password) => axios.post(`${baseUrl}/login`, { userName, password });
export const signupUser = (userName, email, password) => axios.post(`${baseUrl}/signup`, { userName, email, password });

export const verifyUser = (token) => axios.get(`${baseUrl}/verifyUser`, { headers: { Authorization: `Bearer ${token}` } });