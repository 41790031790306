import { FETCH_ALL, CREATE, UPDATE, DELETE, LIKE, FETCH_USER_POSTS, UNLIKE_POST } from "../constants/actionTypes";

export default (posts = [], action) => {
    switch (action.type) {

        case DELETE:
            return posts.filter((post) => post._id !== action.payload);
        case UPDATE:
        case LIKE:
            return posts.map((post) => post._id == action.payload._id ? action.payload : post);
        case UNLIKE_POST:
            return posts.map((post) =>
                post._id === action.payload._id ? { ...post, likeCount: action.payload.likeCount, likedBy: action.payload.likedBy } : post
            );
        case FETCH_ALL:
            return action.payload;
        case CREATE:
            return [...posts, action.payload];
        case FETCH_USER_POSTS:
            return action.payload;
        default:
            return posts;
    }
}