import imageCompression from 'browser-image-compression';
import { useEffect, useState } from "react";
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/esm/Button';
import Modal from "react-bootstrap/esm/Modal";
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import { createPost, updatedPost } from "../../actions/posts.js";
import './Form.css';


const Form = ({ currentId, setCurrentId, onToggleMarkerVisibility, showModal, setShowModal, postData, setPostData, showFeedbackWindowStartpage }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const post = useSelector((state) => currentId ? state.posts.find((p) => p._id === currentId) : null);
    const [showToast, setShowToast] = useState(false);
    const [tags, setTags] = useState([]);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const toggleMarkerVisibility = (visible) => {
        onToggleMarkerVisibility(visible);
    };

    const dataURLtoBlob = (dataURL) => {
        const base64Split = dataURL.split(',');
        const mimeString = base64Split[0].split(':')[1].split(';')[0];
        const byteString = atob(base64Split[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
      
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
      
        return new Blob([arrayBuffer], { type: mimeString });
    };
    
    const handleFileChange = async (base64) => {
        try {
            const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            };
        
            const blob = dataURLtoBlob(base64);
            const compressedFile = await imageCompression(blob, options);
            const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile);
        
            setPostData({ ...postData, selectedFile: compressedBase64 });
        } catch (error) {
            console.log('Fehler bei der Bildkomprimierung:', error);
        }
    };

    const loadCoordinates = async () => {
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;
            const coordinates = [longitude, latitude];

            setPostData((prevData) => ({
                ...prevData,
                location: {
                    type: "Point",
                    coordinates: coordinates
                }
            }));
            showFeedbackWindow("Der aktuelle Standort wurde geladen.", 3000);
        } catch (error) {
            showFeedbackWindow("Standort nicht freigegeben. Nutze bitte den Marker-Button.", 3000);
            console.log("Fehler bei der Ermittlung des Standorts:", error);
        }
    };

    useEffect(() => {
        if (post) {
            setPostData(post);
            setShowModal(true);
            setTags(post.tags || []);
        } else {
            setPostData({
                title: "",
                creator: user?.userName || "",
                tags: [],
                selectedFile: "",
                description: "",
                location: null
            });
            setShowModal(false);
        }
    }, [post, user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedPostData = {
            ...postData,
            tags: tags
        };

        if (!user) {
            console.log("Benutzer ist nicht angemeldet. Place kann nicht erstellt werden.");
            return;
        }

        if (currentId) {
            if (updatedPostData.location && updatedPostData.location.coordinates && updatedPostData.location.coordinates.length === 2) {
                dispatch(updatedPost(currentId, updatedPostData));
                showFeedbackWindowStartpage("Glückwunsch. Place wurde erstellt.", true, 5000);
            } else {
                showFeedbackWindow("Nutze bitte die Buttons unten um deinen Standort zu laden oder einen auf der Karte zu markieren.", 5000);
                return;
            }
        } else {
            if (updatedPostData.location && updatedPostData.location.coordinates && updatedPostData.location.coordinates.length === 2) {
                dispatch(createPost(updatedPostData));
                showFeedbackWindowStartpage("Glückwunsch. Place wurde erstellt.", true, 5000);
            } else {
                showFeedbackWindow("Nutze bitte die Buttons unten um deinen Standort zu laden oder einen auf der Karte zu markieren.", 5000);
                return;
            }
        }
        setShowModal(false);
        clear();
    };
      
    const clear = () => {
        setCurrentId(null);
        setPostData({ title: "", creator: "", tags: [], selectedFile: "", description: "", location: null });
        setTags([]);
    };

    const handleShowModal = () => {
        if (user) {
            setShowModal(true);
            setPostData((prevData) => ({
                // Übergeben des userName an die setPostData-Funktion
                ...prevData,
                creator: user.userName
            }));

        } else {
            setShowToast(true);
        }
    };

    const handleHideModal = () => {
        setShowModal(false);
        toggleMarkerVisibility(false);
        clear();
    };

    const placeMarker = () => {
        setShowModal(false);
        toggleMarkerVisibility(true);
    };

    const placeKoordMarker = () => {
        loadCoordinates();
    };


    // Tags:
    const handleKeyDown = (e) => {
        if (e.key !== 'Enter' && e.key !== ' ') return;
        const value = e.target.value.trim().toLowerCase();
        if (value !== '') {
            setTags([...tags, value]);
            e.target.value = '';
        }
    };

    const removeTag = (tag) => {
        const updatedTags = tags.filter((t) => t !== tag);
        setTags(updatedTags);
    };

    const showFeedbackWindow = (message, duration = 3000) => {
        setFeedbackMessage(message);
        setShowFeedback(true);
        setTimeout(() => {
            setShowFeedback(false);
        }, duration);
    };

    return (
        <div>
            <div id="button">
                <Button id="postbtn" className="custom-button" variant="secondary" onClick={handleShowModal} >
                    <img src="add.png" alt="Symbol zum Hinzufügen eines Places" />
                </Button>
            </div>

            <Modal show={showModal} onHide={handleHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Place {currentId ? 'bearbeiten' : 'erstellen'} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showFeedback && (
                        <div className="feedback-window">
                            <div className="feedback-message">Info:</div>
                            <div className="feedback-info">{feedbackMessage}</div>
                        </div>
                    )}
                    <form >
                        <div className="form-group">
                            <label htmlFor="title">Titel</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                placeholder="Benenne deinen Place"
                                value={postData.title}
                                onChange={(e) => setPostData({ ...postData, title: e.target.value })}
                            />
                        </div>

                        <div className="form-group" >
                            <label >Tags</label>
                            <div className="tags-input-container-create">
                                {tags.map((tag, index) => (
                                    <div className="tag-item-create" key={index}>
                                        <span className="text">{tag}</span>
                                        <span className="tag-item-create-close" onClick={() => removeTag(tag)}>
                                            &times;
                                        </span>
                                    </div>
                                ))}
                                <input onKeyDown={handleKeyDown} type="text" className="tags-input-create" placeholder={tags.length === 0 ? 'Gib passende Tags ein' : ''} />
                            </div>
                        </div>

                        <div className="form-group-beschreibung">
                            <label htmlFor="Beschreibung" className="mt-3">Beschreibung </label>
                            <input
                                type="text"
                                className="form-control"
                                id="Beschreibung"
                                name="Beschreibung"
                                placeholder="Deine Beschreibung zum Place"
                                value={postData.description}
                                maxLength={400} // Begrenzung der Zeichenanzahl
                                onChange={(e) => setPostData({ ...postData, description: e.target.value })}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="picture" className="mt-3"> Bild</label>
                            <div className="custom-file">
                                <FileBase
                                    id="picture"
                                    type="file"
                                    multiple={false}
                                    onDone={({ base64 }) => handleFileChange(base64)}
                                />
                            </div>
                            <img src={postData.selectedFile} className="image-preview" />
                        </div>
                    </form>
                </Modal.Body>

                <div id="createPlaceFooter">
                    <div className="buttonsLeft">
                        <Button id="delete" variant="secondary" onClick={clear} className="custom-button2" title="Eingaben zurücksetzen">
                            <img src="delete.png" width="24px" alt="Löschbutton" />
                        </Button>
                    </div>

                    <div className="buttonsRight">
                        <Button id="position" variant="secondary" onClick={placeKoordMarker} className="custom-button" title="Aktuelle Position auswählen">
                            <img src="position.png" width="24px" alt="Aktuelle Position Button" />
                        </Button>
                        <Button id="selectPosition" variant="secondary" onClick={placeMarker} className="custom-button" title="Position auf Karte wählen">
                            <img src="selectPosition.png" width="24px" alt="Position auf Karte auswählen Button" />
                        </Button>
                        <Button id="submit" variant="primary" type="submit" onClick={handleSubmit} className="custom-button">
                            {currentId ? 'Bearbeiten' : 'Erstellen'}
                        </Button>
                    </div>
                </div>
            </Modal>

            <Toast show={showToast} onClose={() => setShowToast(false)} style={{ position: "fixed", top: "67px", right: "5px", width: "250px" }}>
                <Toast.Header closeButton className="d-flex justify-content-between">
                    <strong className="mr-auto">Anmeldung erforderlich</strong>
                </Toast.Header>
                <Toast.Body style={{ color: "black" }}>
                    Bitte melden Sie sich an, um einen Place zu erstellen.
                </Toast.Body>
            </Toast>
        </div>
    );
};

export default Form;