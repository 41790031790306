import 'leaflet/dist/leaflet.css';
import _ from 'lodash';
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPosts } from "./actions/posts.js";
import StartPage from './components/StartPage';
import { authenticateUser } from "./actions/users.js";

const App = () => {
  const dispatch = useDispatch();
  const getPostsFromGeolocation = useCallback((latitude, longitude) => {
    dispatch(getPosts(latitude, longitude));
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(authenticateUser(token));
    }
  }, [dispatch]);

  useEffect(() => {
    const defaultLatitude = 52.546420;
    const defaultLongitude = 13.353118;

    const debouncedGeolocation = _.debounce((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      getPostsFromGeolocation(latitude, longitude);
    }, 500); // Adjust the debounce delay as per your requirement

    navigator.geolocation.getCurrentPosition(
      (position) => debouncedGeolocation(position),
      (error) => {
        console.log("Fehler beim Abfragen des Standorts:", error);
        getPostsFromGeolocation(defaultLatitude, defaultLongitude);
      }
    );
  }, [getPostsFromGeolocation]);

  return (
    <>
      <div>
        <StartPage />
      </div>
    </>
  )
}

export default App;