import L from 'leaflet';
import React, { useEffect, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { createComment, deleteComment, getCommentsFromPlace } from "../actions/comment";
import { deletePost, likePost, unlikePost } from "../actions/posts";
import './PlaceMarker.css';

const PlaceMarker = React.memo(({ post, setCurrentId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [isLiked, setIsLiked] = useState(post.isLiked || false);
  const [showDetails, setShowDetails] = useState(false);
  const [commentText, setCommentText] = useState('');
  const comments = useSelector((state) => state.comments);
  const [currentPlaceComments, setCurrentPlaceComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const myIcon = L.icon({
    iconUrl: post.description ? 'Place.png' : 'nodescriptionMarker.png',
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [1, 430]
  });

  useEffect(() => {
    dispatch(getCommentsFromPlace(post._id));
  }, [dispatch, post._id]);

  useEffect(() => {
    setCurrentPlaceComments(comments.filter((comment) => comment.place === post._id));
  }, [comments, post._id]);

  useEffect(() => {
    // Überprüfen, ob der Benutzer den Post bereits geliked hat
    if (user && post.likedBy.includes(user._id)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  }, [post, user]);

  const handleDetails = () => {
    dispatch(getCommentsFromPlace(post._id));
    setShowDetails(!showDetails);
  };

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleLike = () => {
    try {
      setIsLoading(true);
      if (isLiked) {
        if (post.likedBy.includes(user._id)) {
          dispatch(unlikePost(post._id, user._id));
          setIsLiked(false);
        } else {
          dispatch(likePost(post._id, user._id));
          setIsLiked(true);
        }
      }
      else {
        dispatch(likePost(post._id, user._id));
        setIsLiked(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
      }
    }
  };

  const handleCommentSubmit = () => {
    if (commentText.trim() !== '') {
      dispatch(createComment({ text: commentText, place: post._id, author: user.userName }));
      setCommentText('');
    }
  };

  const handleDeleteComment = (e, commentId) => {
    e.stopPropagation();
    console.log(commentId)
    dispatch(deleteComment(commentId))
  };

  const map = useMap();

  //Zum angeklickten Place fliegen, wird erstmal nicht benutzt
  const handlePopupOpen = () => {
    const { location } = post;
    const { coordinates } = location;

    if (coordinates && coordinates.length === 2) {
      const [longitude, latitude] = coordinates;
      map.flyTo([latitude, longitude]);
    }
  };

  return (
    <Marker
      key={post._id}
      position={[post.location.coordinates[1], post.location.coordinates[0]]}
      icon={myIcon}
    >
      <Popup className="custom-popup">
        <div className="popup-content">

          <div id="imgPlace">
            <img src={post.selectedFile} className="popup-img" ></img>
          </div>
          <div id="placeNameAndButtons">
            <h4 className="placeName">{post.title}</h4>

            {user?.userName === post.creator && (
              <div id="buttonsPost">
                <button id="editbtn" type="button" className="btn btn-secondary" onClick={() => setCurrentId(post._id)}>
                  <img src="edit.png" width="24px" alt="Editierbutton" />
                </button>
                <button id="deletebtn" type="button" className="btn btn-secondary" onClick={() => dispatch(deletePost(post._id))}>
                  <img src="delete.png" width="24px" alt="Löschbutton" />
                </button>
              </div>
            )}
          </div>

          <p className={`description ${post.description.split(' ').length > 50 ? 'scrollable' : ''}`}>{post.description}</p>
          <div className="tags">
            {post.tags &&
              post.tags.map((tag) => (
                <div className="tag-item-view" key={tag}>
                  <span className="text">{tag}</span>
                </div>
              ))}
          </div>

          <div id="authorAndLike">
            <p className="author"> Erstellt von {user?.userName === post.creator ? "Dir" : `${post.creator}`} </p>
            {user &&
              <button id="likebtn" type="button" className={`btn ${isLiked ? "liked" : "like"}`}
                onClick={handleLike} disabled={isLoading}
              >
                <img src="like.png" width="24px" alt="Likebutton" /> {post.likeCount}
              </button>
            }
          </div>

          <div className="Popup-Place">
            <div className="comment-buttons-container">
              <button
                id="openComments"
                type="button"
                className="btn btn-primary"
                onClick={handleDetails}
              >
                Kommentare
              </button>
            </div>
          </div>

          {showDetails && (
            <div className="comments-container">
              <div className="comments-content">
                {currentPlaceComments.map((comment) => (
                  <div key={comment._id} className="comment-box">
                    <p className="comment-text">
                      <span className="comment-user">{comment.author}</span>: {comment.text}
                      {user?.userName === comment.author && (
                        <button
                          id="deleteComment"
                          type="button"
                          className="Löschen-Button"
                          onClick={(e) => handleDeleteComment(e, comment._id)}
                        >
                          <img src="delete.png" width="24px" alt="Löschbutton" />
                        </button>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {user && showDetails && (
            <div className="comment-input-container">
              <textarea
                className="Kommentar-hinterlassen"
                value={commentText}
                onChange={handleCommentChange}
                placeholder="Kommentar hinterlassen"
              />
              <button
                id="commentSubmit"
                type="button"
                className="btn btn-primary"
                onClick={handleCommentSubmit}
                disabled={commentText.trim() === ''}
              >
                Kommentieren
              </button>
            </div>
          )}
        </div>
      </Popup>
    </Marker>
  );
});

export default PlaceMarker;