import { useEffect, useState } from 'react';

const TagsInput = ({ onSearch }) => {
  const [tags, setTags] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [tagCount, setTagCount] = useState(0);

  useEffect(() => {
    if (onSearch) {
      onSearch(tags);
    }
  }, [tags]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      if (tagCount < 3) {
        const newTag = e.target.value.trim().toLowerCase();
        if (newTag !== '') {
          setTags([...tags, newTag]);
          setTagCount(tagCount + 1);
        }
        setSearchText('');
      }
    }
  };

  const removeTag = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
    onSearch(updatedTags);
    setTagCount(tagCount - 1);
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="tags-input-container">
      <div className='tags-view'>
        {tags.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text">{tag}</span>
            <span className="close" onClick={() => removeTag(tag)}>
              &times;
            </span>
          </div>
        ))}
      </div>
      <input
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        value={searchText}
        type="text"
        className="tags-input"
        placeholder={tags.length === 0 && !searchText ? 'Suche nach z.B. Park' : ''}
        disabled={tagCount >= 3}
      />
    </div>
  );
};

export default TagsInput;
